window.balloonShow = function (event, width, txtID) {
    displayBalloon(event, width, document.getElementById(txtID).innerHTML, "BalloonLayer");
}
window.balloonShowLagerKonfigInfo = function (event, width, txtID) {
    displayBalloonLagerKonfigInfo(event, width, document.getElementById(txtID).innerHTML, "BalloonLayer");
}

//--------------------------------------------------------------------------------
//Display balloon.
//--------------------------------------------------------------------------------
export function displayBalloon(event, width, txt, id) {
    var posLeft;
    var posTop;
    if (document.documentElement.scrollLeft >= document.body.scrollLeft) {
        posLeft = document.documentElement.scrollLeft;
    } else {
        posLeft = document.body.scrollLeft;
    }
    if (document.documentElement.scrollTop >= document.body.scrollTop) {
        posTop = document.documentElement.scrollTop;
    } else {
        posTop = document.body.scrollTop;
    }
    posLeft = (posLeft + event.clientX) - 30;
    posTop = (posTop + event.clientY) + 20;
    document.getElementById(id).innerHTML = txt;
    document.getElementById(id).style.left = posLeft + 'px';
    document.getElementById(id).style.top = posTop + 'px';
    document.getElementById(id).style.width = width;
    document.getElementById(id).style.visibility = "visible";
    document.getElementById(id).style.display = "block";
}

function displayBalloonLagerKonfigInfo(event, width, txt, id) {
    var posLeft;
    var posTop;
    if (document.documentElement.scrollLeft >= document.body.scrollLeft) {
        posLeft = document.documentElement.scrollLeft;
    } else {
        posLeft = document.body.scrollLeft;
    }
    if (document.documentElement.scrollTop >= document.body.scrollTop) {
        posTop = document.documentElement.scrollTop;
    } else {
        posTop = document.body.scrollTop;
    }
    posLeft = (posLeft + event.clientX) + 20;
    posTop = (posTop + event.clientY) - 300;
    document.getElementById(id).innerHTML = txt;
    document.getElementById(id).style.left = posLeft + 'px';
    document.getElementById(id).style.top = posTop + 'px';
    document.getElementById(id).style.width = width;
    document.getElementById(id).style.visibility = "visible";
}

function displayBalloonLagerStatus(event, width, id) {
    var posLeft;
    var posTop;
    if (document.documentElement.scrollLeft >= document.body.scrollLeft) {
        posLeft = document.documentElement.scrollLeft;
    } else {
        posLeft = document.body.scrollLeft;
    }
    if (document.documentElement.scrollTop >= document.body.scrollTop) {
        posTop = document.documentElement.scrollTop;
    } else {
        posTop = document.body.scrollTop;
    }
    posLeft = (posLeft + event.clientX) - 30;
    posTop = (posTop + event.clientY) + 20;
    var element = document.getElementById(id);
    if (element != null) {
        element.style.left = posLeft + 'px';
        element.style.top = posTop + 'px';
        element.style.width = width;
        element.style.visibility = "visible";
    }
}
//--------------------------------------------------------------------------------
//Shop balloon layer for lagerstatus.
//--------------------------------------------------------------------------------
window.balloonShowLagerStatus = function (event, width, tilgjForSalg, tilgjForSalgLev, produktStatusID, bekreftet, erBestilt) {
    var sprDivId = '';
    if (isNaN(tilgjForSalg)) {
        tilgjForSalg = tilgjForSalg.trim();
    }
    if (isNaN(tilgjForSalgLev)) {
        tilgjForSalgLev = tilgjForSalgLev.trim();
    }
    if (tilgjForSalg == 'Ubekreftet' || tilgjForSalg == 'Unconfirmed' || tilgjForSalg == 'Ubekr.')
        tilgjForSalg = 0;
    if (tilgjForSalg == '20+' || tilgjForSalg == '100+')
        tilgjForSalg = 20;
    if (tilgjForSalgLev == 'Ubekreftet' || tilgjForSalgLev == 'Unconfirmed' || tilgjForSalgLev == 'Ubekr.')
        tilgjForSalgLev = 0;
    if (tilgjForSalgLev == '20+' || tilgjForSalgLev == '100+')
        tilgjForSalgLev = 20;
    if (tilgjForSalg > 0) {
        //--> på lager
        sprDivId = 'BalloonLayer1';
    }
    else if (tilgjForSalg == 0 && tilgjForSalgLev == 0 && (produktStatusID == 1 || produktStatusID == 4)) {
        if (bekreftet.toLowerCase() == 'true') {
            //Ikke på lager, bekretet inn på lager.
            sprDivId = 'BalloonLayer2';
        }
        else {
            //Ikke på lager, ubekretet inn på lager.
            if (erBestilt.toLowerCase() == 'true')
                sprDivId = 'BalloonLayer3';
            else
                sprDivId = 'BalloonLayer4';
        }
    }
    else if (tilgjForSalg == 0 && tilgjForSalgLev > 0 && (produktStatusID == 1 || produktStatusID == 4)) {
        if (visFjernlager == 'True' && summerEgetLagerOgLev == 'False') {
            //Ikke på eget lager, men på fjernlager. 
            sprDivId = 'BalloonLayer5';
        }
        else {
            //Ikke på lager, ubekretet inn på lager.
            sprDivId = 'BalloonLayer6';
        }
    }
    else if (tilgjForSalg == 0 && produktStatusID == 2) {
        //Ikke på lager, men bestillingsvare
        sprDivId = 'BalloonLayer4';
    }
    displayBalloonLagerStatus(event, width, sprDivId);
}

window.balloonShowLagerStatusFjernlager = function (event, width, tilgjForSalg, tilgjForSalgLev, produktStatusID, bekreftet) {
    if (isNaN(tilgjForSalg)) {
        tilgjForSalg = tilgjForSalg.trim();
    }
    if (isNaN(tilgjForSalgLev)) {
        tilgjForSalgLev = tilgjForSalgLev.trim();
    }
    var sprDivId = '';
    if (tilgjForSalg == '20+' || tilgjForSalg == '100+')
        tilgjForSalg = 20;
    if (tilgjForSalgLev == '20+' || tilgjForSalgLev == '100+')
        tilgjForSalgLev = 20;
    if (tilgjForSalgLev > 0) {
        //--> på lager
        sprDivId = 'BalloonLayerSupplierWithStock';
    }
    else {
        if (tilgjForSalg > 0) {
            sprDivId = 'BalloonLayerSupplierWithStock';
        }
        else {
            sprDivId = 'BalloonLayerSupplierWithoutStock';
        }
    }
    displayBalloonLagerStatus(event, width, sprDivId);
}

//--------------------------------------------------------------------------------
//Hide balloon layer.
//--------------------------------------------------------------------------------
window.balloonHide = function () {
    document.getElementById("BalloonLayer").style.visibility = "hidden";
}
window.balloonHide2 = function () {
    document.getElementById("BalloonLayer6").style.visibility = "hidden";
}
window.balloonHideLagerStatus = function () {
    try {
        document.getElementById("BalloonLayer1").style.visibility = "hidden";
        document.getElementById("BalloonLayer2").style.visibility = "hidden";
        document.getElementById("BalloonLayer3").style.visibility = "hidden";
        document.getElementById("BalloonLayer4").style.visibility = "hidden";
        document.getElementById("BalloonLayer5").style.visibility = "hidden";

        var el = document.getElementById("BalloonLayerSupplierWithStock");
        if (el != null)
            el.style.visibility = "hidden";
        el = document.getElementById("BalloonLayerSupplierWithoutStock");
        if (el != null)
            el.style.visibility = "hidden";
        el = document.getElementById("BalloonLayer6");
        if (el != null)
            el.style.visibility = "hidden";

    }
    catch (err) { }
}
